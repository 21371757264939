import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Formik } from 'formik'
import { HiOutlineTrash } from 'react-icons/hi'
import {
    createVehicle,
    getOrgVehiclePage,
    requestFactSheet,
    updateVehicle,
} from 'features/dashboard/Vehicle.actionTypes'
import { getCategories, getOrganizationTeams } from 'features/dashboard/selectors'
import {
    Button,
    ButtonGroup,
    Input,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    UncontrolledTooltip,
} from 'reactstrap'
import { customInputForm } from 'ui/common'
import { BsInfoCircle } from 'react-icons/bs'
import DropdownSelect from 'layout-components/Dropdown'

const VehiclesModal = ({
    slug,
    form,
    setForm,
    modal,
    toggle,
    vehicle = {},
    currentPageNumber,
    filters,
}) => {
    const dispatch = useDispatch()
    const teams = useSelector(getOrganizationTeams)
    const categories = useSelector(getCategories)
    const [tab, setTab] = useState('general')
    const [scrollPosition, setScrollPosition] = useState('top')
    const [toggleBattery, setToggleBattery] = useState(false)
    const [team, setTeam] = useState(teams?.find((t) => t.uuid === vehicle.team)?.title || '')
    const [category, setCategory] = useState(vehicle.category_name)
    const [integrationType, setIntegrationType] = useState(vehicle?.integration_type || '')
    const [vehicleType, setVehicleType] = useState(vehicle.vehicle_type?.toUpperCase() || '')
    const dischargeValue = parseFloat(vehicle.discharge_per_meter)

    const setInitialValues = () => {
        switch (true) {
            case tab === 'battery' && form === 'edit-vehicle':
                return {
                    uuid: vehicle.uuid,
                    full_battery: vehicle.full_battery,
                    working_battery_level: vehicle.working_battery_level,
                    emergency_battery_level: vehicle.emergency_battery_level,
                    emergency_distance: vehicle.emergency_distance,
                    discharge_per_meter: vehicle.discharge_per_meter,
                    driveable_distance: vehicle.driveable_distance,
                    range: vehicle.range,
                    use_team_battery_defaults: vehicle.use_team_battery_defaults,
                    battery_management_enabled: vehicle.battery_management_enabled,
                }
            case tab === 'general' && form === 'edit-vehicle':
                return {
                    verbose_name: vehicle.verbose_name,
                    uuid: vehicle.uuid,
                    active: vehicle.active,
                    enable_free_roaming: vehicle.enable_free_roaming,
                    category: vehicle.category,
                    vehicle_type: vehicle.vehicle_type,
                    team: vehicle.team,
                    custom_manufacturer: vehicle.custom_manufacturer,
                    integration_type: vehicle.integration_type,
                    vehicle_serial_number: vehicle.vehicle_serial_number,
                }

            case tab === 'traffic' && form === 'edit-vehicle':
                return {
                    uuid: vehicle.uuid,
                    message_frequency: vehicle.message_frequency,
                    traffic_control_delay: vehicle.traffic_control_delay,
                }

            case tab === 'dimensions' && form === 'edit-vehicle':
                return {
                    uuid: vehicle.uuid,
                    dimensions: vehicle.dimensions,
                }
            case tab === 'map_offsets' && form === 'edit-vehicle':
                return {
                    uuid: vehicle.uuid,
                    initial_position: vehicle.initial_position,
                }
            default:
                return { vehicle }
        }
    }

    useEffect(() => {
        if (!vehicle?.emergency_distance) {
            setToggleBattery(true)
        }
    }, [vehicle.emergency_distance])

    const handleBatteryToggle = () => setToggleBattery(!toggleBattery)

    const handleSubmit = (values) => {
        const { is_collision_detected, uuid, ...data } = values

        if (form === 'edit-vehicle') {
            dispatch(updateVehicle({ uuid, ...data })).then(({ error }) => {
                if (!error) {
                    dispatch(getOrgVehiclePage({ slug, page: currentPageNumber, filters }))
                    toggle()
                }
            })
        }

        if (form === 'add-vehicle') {
            dispatch(createVehicle({ slug, ...data })).then(({ error }) => {
                if (!error) {
                    dispatch(getOrgVehiclePage({ slug, page: currentPageNumber, filters }))
                    toggle()
                }
            })
        }
    }

    const renderArrow = () => {
        if (tab === 'general') {
            if (scrollPosition === 'bottom') {
                return (
                    <img
                        src="/svgs/user-icon/arrow.svg"
                        alt="arrow"
                        className="rotated-up-arrow-modals"
                    />
                )
            } else if (scrollPosition === 'top') {
                return (
                    <img
                        src="/svgs/user-icon/arrow.svg"
                        alt="arrow"
                        className="rotated-down-arrow-modals"
                    />
                )
            } else {
                return (
                    <div className="d-flex">
                        <img
                            src="/svgs/user-icon/arrow.svg"
                            alt="arrow"
                            className="rotated-up-arrow-modals"
                        />
                        <img
                            src="/svgs/user-icon/arrow.svg"
                            alt="arrow"
                            className="rotated-down-arrow-modals"
                        />
                    </div>
                )
            }
        }
        return <div></div>
    }

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - 5 <= e.target.clientHeight
        const top = e.target.scrollTop === 0

        if (bottom) {
            setScrollPosition('bottom')
        } else if (top) {
            setScrollPosition('top')
        } else {
            setScrollPosition('middle')
        }
    }

    const handleVehicleCategoryChange = (category, setFieldValue, categoryName) => {
        const selectedCategoryObj = categories.find((cat) => cat.uuid === category)
        setCategory(categoryName)
        setVehicleType(selectedCategoryObj?.vehicle_type?.toUpperCase() || 'AMR')
        setFieldValue('category', category || null)
        setFieldValue('vehicle_type', selectedCategoryObj?.vehicle_type || 'amr')
    }

    const integrationTypes = [
        { uuid: '', name: '' },
        { uuid: 'ROS', name: 'ROS' },
        { uuid: 'MIR', name: 'MiR' },
        { uuid: 'NOOVELIA', name: 'NOOVELIA' },
        { uuid: 'VDA5050', name: 'VDA5050' },
        { uuid: 'Wewo', name: 'Wewo' },
        { uuid: 'Youibot', name: 'Youibot' },
        { uuid: 'ROBOLIGENT', name: 'Roboligent' },
    ]

    return (
        <Modal isOpen={modal} toggle={toggle} centered size="lg">
            <ModalBody
                style={{ padding: '24px 24px 0px 24px', position: 'relative', overflowY: 'auto' }}
                onScroll={handleScroll}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/task-modal/smiley-robot.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="d-flex flex-column">
                    <span className="org-modal-header">
                        {form === 'add-vehicle' ? 'New vehicle' : 'Vehicle settings'}
                    </span>
                    <div className="modals-container-buttons">
                        <ButtonGroup className="org-button-group">
                            <Button
                                className={`${
                                    tab === 'general' ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('general')
                                }}
                            >
                                General
                            </Button>
                            {form === 'edit-vehicle' && (
                                <Button
                                    className={`${
                                        tab === 'battery' ? 'org-button-selected' : ''
                                    } org-button`}
                                    style={{ borderRadius: '6px' }}
                                    onClick={() => {
                                        setTab('battery')
                                    }}
                                >
                                    Battery
                                </Button>
                            )}
                            <Button
                                className={`${
                                    tab === 'traffic' ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('traffic')
                                }}
                            >
                                Traffic control
                            </Button>
                            <Button
                                className={`${
                                    tab === 'dimensions' ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('dimensions')
                                }}
                            >
                                Dimensions
                            </Button>
                            <Button
                                className={`${
                                    tab === 'map_offsets' ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('map_offsets')
                                }}
                            >
                                Map offsets
                            </Button>
                            {vehicle.uuid && vehicle.integration_type === 'VDA5050' && (
                                <Button
                                    className={`${
                                        tab === 'factsheet' ? 'org-button-selected' : ''
                                    } org-button`}
                                    style={{ borderRadius: '6px' }}
                                    onClick={() => {
                                        setTab('factsheet')
                                    }}
                                >
                                    Factsheet
                                </Button>
                            )}
                        </ButtonGroup>
                    </div>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={setInitialValues()}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue, handleSubmit }) => (
                        <Form
                            enableReinitialize
                            style={{ marginTop: '14px' }}
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                        >
                            {tab === 'general' && (
                                <>
                                    <FormGroup>
                                        <Label className="modals-labels" for="verbose_name">
                                            Name
                                        </Label>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="text"
                                            value={values.verbose_name || ''}
                                            name="verbose_name"
                                            placeholder="Name"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="modals-labels" for="team">
                                            Team *
                                        </Label>
                                        <DropdownSelect
                                            selected={team}
                                            setSelected={setTeam}
                                            options={teams.map((team) => team)}
                                            setFieldValue={setFieldValue}
                                            fieldValue="team"
                                        />
                                        {/* <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="select"
                                            required
                                            value={values.team || ''}
                                            name="team"
                                        >
                                            <option value=""></option>
                                            {teams.map((team) => (
                                                <option value={team.uuid} key={team.uuid}>
                                                    {team.title}
                                                </option>
                                            ))}
                                        </Field> */}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="modals-labels" for="category">
                                            Category
                                        </Label>
                                        <DropdownSelect
                                            selected={category}
                                            setSelected={setCategory}
                                            options={[' ', ...categories.map((cat) => cat)]}
                                            setFieldValue={setFieldValue}
                                            onClick={handleVehicleCategoryChange}
                                            fieldValue="category"
                                        />
                                        {/* <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="select"
                                            name="category"
                                            value={values.category || ''}
                                            onChange={(e) =>
                                                handleVehicleCategoryChange(e, setFieldValue)
                                            }
                                        >
                                            <option value=""></option>
                                            {categories.map((cat) => (
                                                <option value={cat.uuid} key={cat.uuid}>
                                                    {cat.title}
                                                </option>
                                            ))}
                                        </Field> */}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="modals-labels" for="custom_manufacturer">
                                            Custom manufacturer
                                        </Label>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="text"
                                            value={values.custom_manufacturer || ''}
                                            name="custom_manufacturer"
                                            placeholder="802540e178b14cd484b624784c51da93"
                                            pattern="^[^\s]+$"
                                        />
                                        <small>Enter a custom manufacturer without spaces.</small>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="modals-labels" for="integration_type">
                                            Integration type
                                        </Label>
                                        <DropdownSelect
                                            selected={integrationType}
                                            setSelected={setIntegrationType}
                                            options={integrationTypes.map((type) => type)}
                                            setFieldValue={setFieldValue}
                                            fieldValue="integration_type"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="modals-labels" for="vehicle_type">
                                            Vehicle Type *
                                        </Label>
                                        <img
                                            src="/svgs/trigger-actions/question-mark.svg"
                                            alt="plus"
                                            id="type_info"
                                            width={16}
                                            height={16}
                                            style={{
                                                cursor: 'pointer',
                                                color: '#C2C5CE',
                                                marginLeft: '4px',
                                            }}
                                        />
                                        <UncontrolledTooltip
                                            placement="left"
                                            target="type_info"
                                            style={{
                                                maxWidth: '20rem',
                                                background: 'white',
                                                textAlign: 'start',
                                                borderRadius: '1rem',
                                                whiteSpace: 'break-spaces',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            -Autonomous Mobile Robots (AMR) <br />
                                            -Automated Guided Vehicles (AGV) <br />
                                            -AGVs are not able to run tasks sent by Meili FMS
                                        </UncontrolledTooltip>
                                        <DropdownSelect
                                            selected={vehicleType}
                                            setSelected={setVehicleType}
                                            options={[
                                                { uuid: 'agv', name: 'AGV' },
                                                { uuid: 'amr', name: 'AMR' },
                                            ]}
                                            setFieldValue={setFieldValue}
                                            fieldValue="vehicle_type"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label
                                            className="modals-labels"
                                            for="vehicle_serial_number"
                                        >
                                            Vehicle serial number
                                        </Label>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="text"
                                            placeholder="802540e178b14cd484b624784c51da93"
                                            value={values.vehicle_serial_number || ''}
                                            name="vehicle_serial_number"
                                            pattern="^[^\s]+$"
                                        />
                                        <small>Enter a serial number without spaces.</small>
                                    </FormGroup>
                                    {/* <FormGroup>
                                        <Label className="modals-labels">
                                            <CustomInput
                                                type="checkbox"
                                                id="batch_tasks"
                                                label="Batch Tasks"
                                                checked={values?.batch_tasks}
                                                onChange={() => {
                                                    setFieldValue(
                                                        'batch_tasks',
                                                        !values.batch_tasks
                                                    )
                                                }}
                                            />
                                        </Label>
                                        
                                    </FormGroup> */}
                                    <FormGroup className="ml-3" check>
                                        <Input
                                            type="checkbox"
                                            id="vehicle_enabled"
                                            required
                                            checked={values.active}
                                            onChange={() => {
                                                setFieldValue('active', !values.active)
                                            }}
                                        />
                                        <Label className="modals-labels">Active *</Label>
                                    </FormGroup>
                                    <FormGroup className="mt-2 ml-3" check>
                                        <Input
                                            type="checkbox"
                                            id="enable_free_roaming"
                                            checked={values.enable_free_roaming}
                                            name="enable_free_roaming"
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'enable_free_roaming',
                                                    e.target.checked
                                                )
                                            }}
                                        />
                                        <Label check>Free roaming</Label>
                                        <BsInfoCircle
                                            className="font-size-l ml-2 info-icon mr-0 rounded-circle"
                                            id="roaming"
                                        />
                                        <UncontrolledTooltip placement="right" target="roaming">
                                            Enables robots to execute tasks even if two station are
                                            not connected with path
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                </>
                            )}
                            {tab === 'battery' && form === 'edit-vehicle' && (
                                <>
                                    <div
                                        className="d-flex justify-content-start align-items-center"
                                        style={{ margin: '12px 0px' }}
                                    >
                                        <FormGroup className="mr-4" style={{ width: '220px' }}>
                                            <Label className="modals-labels">Full battery</Label>
                                            <div className="d-flex align-items-center">
                                                <Field
                                                    className="modals-inputs"
                                                    component={customInputForm}
                                                    type="number"
                                                    value={values?.full_battery}
                                                    style={{ paddingRight: '2rem' }}
                                                    name="full_battery"
                                                    placeholder="Full battery"
                                                />
                                                <span style={{ marginLeft: '-1.5rem' }}>%</span>
                                            </div>
                                        </FormGroup>
                                        <FormGroup className="ml-1" style={{ width: '220px' }}>
                                            <Label className="modals-labels">
                                                Working battery level
                                            </Label>

                                            <span id="working-b-l">
                                                <BsInfoCircle className="font-size-l info-icon  mr-0 ml-2 rounded-circle" />
                                            </span>

                                            <UncontrolledTooltip
                                                placement="left"
                                                target="working-b-l"
                                                delay={{ show: 100, hide: 400 }}
                                            >
                                                The level at which the robot is released from the
                                                charging station and becomes available for a task.
                                            </UncontrolledTooltip>

                                            <div className="d-flex align-items-center">
                                                <Field
                                                    className="modals-inputs"
                                                    component={customInputForm}
                                                    type="number"
                                                    style={{ paddingRight: '2rem' }}
                                                    value={values?.working_battery_level}
                                                    name="working_battery_level"
                                                    placeholder="Working battery level"
                                                />
                                                <span style={{ marginLeft: '-1.5rem' }}>%</span>
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <FormGroup>
                                        <div className="d-flex">
                                            <FormGroup
                                                switch
                                                style={{ margin: '0px 0px 12px 12px' }}
                                            >
                                                <Input
                                                    style={{ height: '20px', width: '36px' }}
                                                    role="switch"
                                                    type="switch"
                                                    className="custom-switch-form modals-labels"
                                                    id="emergency_battery_level"
                                                    onChange={() => {
                                                        if (!toggleBattery) {
                                                            setFieldValue('emergency_distance', '')
                                                        } else {
                                                            setFieldValue(
                                                                'emergency_battery_level',
                                                                ''
                                                            )
                                                        }
                                                        handleBatteryToggle()
                                                    }}
                                                    checked={toggleBattery}
                                                />
                                                <Label check className="mt-1 ml-2 modals-labels">
                                                    Emergency battery level{' '}
                                                </Label>
                                            </FormGroup>
                                            <span id="emergency-b-l">
                                                <BsInfoCircle className="font-size-l info-icon mr-0 ml-2 rounded-circle mt-2" />
                                            </span>
                                            <UncontrolledTooltip
                                                placement="left"
                                                target="emergency-b-l"
                                                delay={{ show: 100, hide: 400 }}
                                            >
                                                The level at which the robot is sent to a charging
                                                station.
                                            </UncontrolledTooltip>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <Field
                                                component={customInputForm}
                                                type="number"
                                                step="any"
                                                className="modals-inputs"
                                                style={{ paddingRight: '2rem' }}
                                                disabled={
                                                    values?.use_team_battery_defaults === true ||
                                                    toggleBattery === false
                                                }
                                                value={values.emergency_battery_level || ''}
                                                name="emergency_battery_level"
                                            />
                                            <span style={{ marginLeft: '-1.5rem' }}>%</span>
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="d-flex">
                                            <Label className="modals-labels">
                                                Emergency distance
                                            </Label>
                                            <span id="emergency-d">
                                                <BsInfoCircle className="font-size-l info-icon ml-2 rounded-circle" />
                                            </span>
                                            <UncontrolledTooltip
                                                placement="left"
                                                target="emergency-d"
                                                delay={{ show: 100, hide: 400 }}
                                            >
                                                The distance that can be covered with the emergency
                                                battery level.
                                            </UncontrolledTooltip>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <Field
                                                component={customInputForm}
                                                className="modals-inputs"
                                                style={{ paddingRight: '2rem' }}
                                                type="number"
                                                step="any"
                                                disabled={
                                                    values?.use_team_battery_defaults === true ||
                                                    toggleBattery === true
                                                }
                                                value={values.emergency_distance || ''}
                                                name="emergency_distance"
                                            />
                                            <span style={{ marginLeft: '-1.5rem' }}>m</span>
                                        </div>
                                        <small>
                                            Note: Cannot set emergency battery level and emergency
                                            distance simultaneously
                                        </small>
                                    </FormGroup>

                                    <FormGroup>
                                        <div className="d-flex">
                                            <Label className="modals-labels">
                                                Discharge per meter
                                            </Label>
                                            <span id="discharge_per_meter">
                                                {' '}
                                                <BsInfoCircle className="font-size-l info-icon mr-0 ml-2 rounded-circle" />
                                            </span>

                                            <UncontrolledTooltip
                                                placement="left"
                                                target="discharge_per_meter"
                                                delay={{ show: 100, hide: 400 }}
                                            >
                                                It is the percentage of the battery that is
                                                discharged when the vehicle covers a distance of one
                                                meter. This value is automatically calculated by
                                                Meili FMS during the execution of missions→
                                                Discharged battery percentage in a meter distance.
                                            </UncontrolledTooltip>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <Field
                                                component={customInputForm}
                                                className="modals-inputs"
                                                style={{ paddingRight: '2rem' }}
                                                type="number"
                                                step="any"
                                                value={dischargeValue}
                                                name="discharge_per_meter"
                                                disabled
                                            />
                                            <span style={{ marginLeft: '-1.8rem' }}>%</span>
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="d-flex">
                                            <Label className="modals-labels">
                                                Driveable distance
                                            </Label>
                                            <span id="driveable_distance">
                                                <BsInfoCircle className="font-size-l info-icon mr-0 ml-2 rounded-circle" />
                                            </span>

                                            <UncontrolledTooltip
                                                placement="left"
                                                target="driveable_distance"
                                                delay={{ show: 100, hide: 400 }}
                                            >
                                                Total distance that the vehicle could travel with
                                                the battery at 100% of its capacity.
                                            </UncontrolledTooltip>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <Field
                                                component={customInputForm}
                                                className="modals-inputs"
                                                type="number"
                                                step="any"
                                                disabled
                                                value={values.driveable_distance | ''}
                                                name="driveable_distance"
                                            />
                                            <span style={{ marginLeft: '-1.8rem' }}>m</span>
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="d-flex">
                                            <Label className="modals-labels">Range:</Label>
                                            <span id="range">
                                                <BsInfoCircle className="font-size-l info-icon mr-0 ml-2 rounded-circle" />
                                            </span>
                                            <UncontrolledTooltip
                                                placement="left"
                                                target="range"
                                                delay={{ show: 100, hide: 400 }}
                                            >
                                                Total distance the vehicle can travel with the
                                                current battery level.
                                            </UncontrolledTooltip>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <Field
                                                component={customInputForm}
                                                className="modals-inputs"
                                                type="number"
                                                step="any"
                                                value={values.range | ''}
                                                name="range"
                                                disabled
                                            />
                                            <span style={{ marginLeft: '-1.8rem' }}>m</span>
                                        </div>
                                    </FormGroup>

                                    <FormGroup switch style={{ margin: '24px 0px 12px 12px' }}>
                                        <Input
                                            style={{ height: '20px', width: '36px' }}
                                            role="switch"
                                            type="switch"
                                            id="use_team_battery_defaults"
                                            checked={values.use_team_battery_defaults}
                                            name="use_team_battery_defaults"
                                            onChange={() => {
                                                setFieldValue(
                                                    'use_team_battery_defaults',
                                                    !values.use_team_battery_defaults
                                                )
                                            }}
                                            className="custom-switch-form modals-labels"
                                        />
                                        <Label check className="mt-1 ml-2">
                                            Use team default settings{' '}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup switch style={{ margin: '12px 0px 24px 12px' }}>
                                        <Input
                                            style={{ height: '20px', width: '36px' }}
                                            role="switch"
                                            type="switch"
                                            id="battery_management_enabled"
                                            checked={values.battery_management_enabled}
                                            name="battery_management_enabled"
                                            onChange={() => {
                                                setFieldValue(
                                                    'battery_management_enabled',
                                                    !values.battery_management_enabled
                                                )
                                            }}
                                            className="custom-switch-form modals-labels"
                                        />
                                        <Label check className="mt-1 ml-2">
                                            {' '}
                                            Enable battery management
                                        </Label>
                                    </FormGroup>
                                </>
                            )}

                            {tab === 'traffic' && (
                                <>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <FormGroup className="mr-4" style={{ width: '220px' }}>
                                            <Label className="modals-labels">
                                                Message frequency
                                            </Label>
                                            <div className="d-flex align-items-center">
                                                <Field
                                                    className="modals-inputs"
                                                    style={{ paddingRight: '2rem' }}
                                                    component={customInputForm}
                                                    type="number"
                                                    step="0.001"
                                                    value={values?.message_frequency || ''}
                                                    name="message_frequency"
                                                    placeholder="Message frequency"
                                                />
                                                <span style={{ marginLeft: '-1.5rem' }}>Hz</span>
                                            </div>
                                        </FormGroup>
                                        <FormGroup className="ml-1" style={{ width: '220px' }}>
                                            <Label className="modals-labels">
                                                Traffic control delay
                                            </Label>
                                            <div className="d-flex align-items-center">
                                                <Field
                                                    className="modals-inputs"
                                                    style={{ paddingRight: '2.2rem' }}
                                                    component={customInputForm}
                                                    type="number"
                                                    step="0.001"
                                                    value={values?.traffic_control_delay || ''}
                                                    name="traffic_control_delay"
                                                    placeholder="Traffic control delay"
                                                />
                                                <span style={{ marginLeft: '-2rem' }}>Sec</span>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </>
                            )}

                            {tab === 'dimensions' && (
                                <>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <FormGroup>
                                            <Label className="modals-labels">Length</Label>
                                            <div className="d-flex align-items-center">
                                                <Field
                                                    className="modals-inputs"
                                                    style={{ paddingRight: '2.4rem' }}
                                                    component={customInputForm}
                                                    type="number"
                                                    value={values?.dimensions?.[0] || ''}
                                                    name="dimensions[0]"
                                                    placeholder="X"
                                                />
                                                <span style={{ marginLeft: '-2.2rem' }}>mm</span>
                                            </div>
                                        </FormGroup>
                                        <FormGroup className="mx-4">
                                            <Label className="modals-labels ml-2">Width</Label>
                                            <div className="d-flex align-items-center mx-2">
                                                <Field
                                                    className="modals-inputs"
                                                    style={{ paddingRight: '2.4rem' }}
                                                    component={customInputForm}
                                                    type="number"
                                                    value={values?.dimensions?.[1] || ''}
                                                    name="dimensions[1]"
                                                    placeholder="Y"
                                                />
                                                <span style={{ marginLeft: '-2.2rem' }}>mm</span>
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="modals-labels">Height</Label>
                                            <div className="d-flex align-items-center">
                                                <Field
                                                    className="modals-inputs"
                                                    style={{ paddingRight: '2.4rem' }}
                                                    component={customInputForm}
                                                    type="number"
                                                    value={values?.dimensions?.[2] || ''}
                                                    name="dimensions[2]"
                                                    placeholder="Z"
                                                />
                                                <span style={{ marginLeft: '-2.2rem' }}>mm</span>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </>
                            )}
                            {tab === 'map_offsets' && (
                                <div className="d-flex justify-content-start align-items-center">
                                    <FormGroup className="mr-4">
                                        <Label className="modals-labels">Length</Label>
                                        <div className="d-flex align-items-center">
                                            <Field
                                                className="modals-inputs"
                                                style={{ paddingRight: '2.4rem' }}
                                                component={customInputForm}
                                                type="number"
                                                value={values?.initial_position?.[0] || ''}
                                                name="initial_position[0]"
                                                placeholder="X"
                                            />
                                            <span style={{ marginLeft: '-2.2rem' }}>mm</span>
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="modals-labels ml-2">Width</Label>
                                        <div className="d-flex align-items-center mx-2">
                                            <Field
                                                className="modals-inputs"
                                                style={{ paddingRight: '2.4rem' }}
                                                component={customInputForm}
                                                type="number"
                                                value={values?.initial_position?.[1] || ''}
                                                name="initial_position[1]"
                                                placeholder="Y"
                                            />
                                            <span style={{ marginLeft: '-2.2rem' }}>mm</span>
                                        </div>
                                    </FormGroup>
                                </div>
                            )}
                            {tab === 'factsheet' && (
                                <div className="w-100 h-100 py-2 px-1">
                                    <Button
                                        className="save-btn-modal modals-new-btns"
                                        onClick={() => dispatch(requestFactSheet(vehicle))}
                                    >
                                        Request factsheet
                                    </Button>
                                </div>
                            )}
                            <div className="new-modal-footer">
                                {form === 'add-vehicle' ? (
                                    renderArrow()
                                ) : (
                                    <Button
                                        color="none"
                                        onClick={() => {
                                            setForm('remove-vehicle')
                                        }}
                                        className="modals-delete-btn"
                                    >
                                        <HiOutlineTrash size={20} className="mr-1" />
                                        Delete
                                    </Button>
                                )}
                                <div className="d-flex">
                                    <Button
                                        className="cancel-btn-modal modals-new-btns"
                                        onClick={() => {
                                            toggle()
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="save-btn-modal modals-new-btns"
                                        style={{ marginLeft: '12px' }}
                                        type="submit"
                                        disabled={
                                            form === 'edit-vehicle' &&
                                            JSON.stringify(values) === JSON.stringify(vehicle)
                                        }
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default VehiclesModal
