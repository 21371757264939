import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Card, CardHeader, UncontrolledTooltip, ButtonGroup, Button } from 'reactstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { Loading } from 'ui/common'
import { selectors } from 'features/dashboard'
import { getTeamVehicles } from 'features/dashboard/Vehicle.actionTypes'
import { getWhitedevDashboard } from 'features/dashboard/selectors'
import { TITLE } from 'config'
import Battery from './Battery'
import WhitelabelVehicle from './WhitelabelVehicle'
import { getTeamAnalytics } from 'features/dashboard/TeamAnalytics.actionTypes'
import { getDevices } from 'features/dashboard/RosSetup.actionTypes'
import ColumnEditor from 'layout-components/ColumnEditor'

const VehicleManage = ({ match }) => {
    const { slug } = match.params
    const dispatch = useDispatch()
    const vehicles = useSelector(selectors.getVehicles)
    const devices = useSelector(selectors.getDevicess)
    const whitedevDashboard = useSelector(getWhitedevDashboard)
    const [activeTab, setActiveTab] = useState('vehicles')
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [lastClickedPageVeh, setLastClickedPageVeh] = useState(1)
    const [lastClickedPageDev, setLastClickedPageDev] = useState(1)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [columnsList, setColumnsList] = useState(
        JSON.parse(localStorage.getItem('columns-vehicles')) || [
            {
                dataField: 'verboseName',
                text: 'Name',
                sort: true,
                headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', textAlign: 'center' },
                style: { overflowX: 'auto', whiteSpace: 'nowrap', textAlign: 'center' },
                classes: 'add-scroll',
                hidden: false,
            },
            {
                dataField: 'vehicleType',
                text: 'Vehicle type',
                headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', textAlign: 'center' },
                style: { overflowX: 'auto', whiteSpace: 'nowrap', textAlign: 'center' },
                classes: 'add-scroll',
                hidden: false,
            },
            {
                dataField: 'location',
                text: 'Location',
                headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', textAlign: 'center' },
                style: { textAlign: 'center' },
                hidden: false,
            },
            {
                dataField: 'current_mission',
                text: 'Mission',
                headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', textAlign: 'center' },
                style: { textAlign: 'center' },
                hidden: false,
            },
            {
                dataField: 'status',
                text: 'Status',
                headerStyle: {
                    backgroundColor: '#F9FAFB',
                    color: 'gray',
                    textAlign: 'center',
                    height: '3rem',
                },
                style: { textAlign: 'center' },
                hidden: false,
            },
            {
                dataField: 'speed',
                text: 'Speed',
                headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', textAlign: 'center' },
                style: { textAlign: 'center' },
                hidden: false,
            },
            {
                dataField: 'battery',
                text: 'Battery',
                headerStyle: {
                    backgroundColor: '#F9FAFB',
                    color: 'gray',
                    textAlign: 'center',
                    width: '10%',
                },
                style: { textAlign: 'end', paddingLeft: '1.5rem' },
                hidden: false,
            },
        ]
    )

    const [devicesColumns, setDevicesColumns] = useState(
        JSON.parse(localStorage.getItem('columns-devices')) || [
            {
                dataField: 'title',
                text: 'Name',
                sort: true,
                headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', textAlign: 'center' },
                style: { overflowX: 'auto', whiteSpace: 'nowrap', textAlign: 'center' },
                classes: 'add-scroll',
                hidden: false,
            },
            {
                dataField: 'identifier',
                text: 'Identifier',
                headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', textAlign: 'center' },
                style: { overflowX: 'auto', whiteSpace: 'nowrap', textAlign: 'center' },
                classes: 'add-scroll',
                hidden: false,
            },
            {
                dataField: 'states',
                text: 'State',
                headerStyle: { backgroundColor: '#F9FAFB', color: 'gray', textAlign: 'center' },
                style: { textAlign: 'center' },
                hidden: false,
            },
            {
                dataField: 'status',
                text: 'online',
                headerStyle: {
                    backgroundColor: '#F9FAFB',
                    color: 'gray',
                    textAlign: 'center',
                    height: '3rem',
                },
                style: { textAlign: 'center' },
                hidden: false,
            },
        ]
    )

    const toggleEdit = () => setDropdownOpen((prevState) => !prevState)

    const renderDashboard =
        whitedevDashboard.data.influx_gauges?.length !== undefined ? true : false

    useEffect(() => {
        if (activeTab === 'vehicles') {
            document.title = `${TITLE} - Vehicles manage`
            setLastClickedPageDev(1)
        }
        if (activeTab === 'devices') {
            document.title = `${TITLE} - Devices manage`
            setLastClickedPageVeh(1)
        }
    }, [activeTab])

    useEffect(() => {
        if (renderDashboard) {
            dispatch(getTeamAnalytics({ slug }))
        }
        dispatch(getDevices({ slug }))
    }, [dispatch, slug, renderDashboard])

    useEffect(() => {
        dispatch(getTeamVehicles({ slug, text: 'detailed=true', page: currentPageNumber }))
    }, [currentPageNumber]) // eslint-disable-line

    const VehicleDetails = ({ vehicle }) => {
        if (!vehicle.details) {
            return '-'
        } else {
            const { xm, ym, lat, lon, rotation } = vehicle.details
            if (lat || lon) {
                return `Lat: ${lat} - Lon: ${lon}`
            } else {
                const formattedXm = xm ? parseFloat(xm).toFixed(2) : '-'
                const formattedYm = ym ? parseFloat(ym).toFixed(2) : '-'
                const formattedRotation = rotation ? parseFloat(rotation).toFixed(2) + '°' : '-'
                const formattedStation = vehicle.current_station ? vehicle.current_station : '-'
                return (
                    <>
                        <span>{`X: ${formattedXm}m - Y: ${formattedYm}m`}</span>
                        <br />
                        <span>{`Rotation: ${formattedRotation}`}</span>
                        <br />
                        <span>{`Station: ${formattedStation}`}</span>
                    </>
                )
            }
        }
    }

    const Status = ({ vehicle }) => {
        if (vehicle.is_online) {
            if (vehicle.vehicle_state === 'emergency_stop') {
                return (
                    <div id="emergency-stop">
                        🟠{' '}
                        <UncontrolledTooltip placement="left" target="emergency-stop">
                            Emergency stop
                        </UncontrolledTooltip>
                    </div>
                )
            }
            if (vehicle.is_charging) {
                return (
                    <div id="charging">
                        🔵{' '}
                        <UncontrolledTooltip placement="left" target="charging">
                            Charging
                        </UncontrolledTooltip>
                    </div>
                )
            }
            if (vehicle.is_on_task) {
                return (
                    <div id="busy">
                        🟡{' '}
                        <UncontrolledTooltip placement="left" target="busy">
                            In use
                        </UncontrolledTooltip>
                    </div>
                )
            }
            return (
                <div id="available">
                    🟢{' '}
                    <UncontrolledTooltip placement="left" target="available">
                        Available
                    </UncontrolledTooltip>
                </div>
            )
        } else {
            return (
                <div id="offline">
                    🔴{' '}
                    <UncontrolledTooltip placement="left" target="offline">
                        Unavailable
                    </UncontrolledTooltip>
                </div>
            )
        }
    }

    const StatusDevice = ({ device }) => {
        if (device.online) {
            return (
                <div id="available">
                    🟢{' '}
                    <UncontrolledTooltip placement="bottom" target="available">
                        Online
                    </UncontrolledTooltip>
                </div>
            )
        } else
            return (
                <div id="offline">
                    ⚪{' '}
                    <UncontrolledTooltip placement="bottom" target="offline">
                        Offline
                    </UncontrolledTooltip>
                </div>
            )
    }

    const visibleColumns = columnsList.filter((column) => !column.hidden)

    const dataList = useMemo(() => {
        if (!vehicles?.teamVehicle) return []
        return vehicles.teamVehicle?.map((vehicle) => ({
            uuid: vehicle.uuid,
            verboseName: vehicle?.verbose_name,
            vehicleType: vehicle?.vehicle_type?.toUpperCase(),
            status: <Status vehicle={vehicle} />,
            location: <VehicleDetails vehicle={vehicle} />,
            current_mission: vehicle.current_mission || '-',
            speed:
                vehicle.details && !isNaN(parseFloat(vehicle.details?.speed))
                    ? parseFloat(vehicle.details.speed).toFixed(2) + 'm/s'
                    : '-',
            battery: <Battery percentage={vehicle.battery_level} uuid={vehicle.uuid} />,
            is_active: vehicle.is_online,
            // current_station: vehicle.current_station,
        }))
    }, [vehicles.teamVehicle])

    const visibleDeviceColumns = devicesColumns?.filter((column) => !column.hidden)

    const devicesList = useMemo(() => {
        if (!devices) return []
        return devices.map((device) => ({
            uuid: device.uuid,
            title: device?.title,
            identifier: device?.identifier,
            states: device?.status || '-',
            status: <StatusDevice device={device} />,
        }))
    }, [devices])

    if (vehicles.status === 'loading') {
        return <Loading />
    }
    if (renderDashboard === true) {
        return <WhitelabelVehicle />
    } else
        return (
            <div>
                <div className="w-100" style={{ marginTop: '-45px' }}>
                    <h3>{activeTab === 'vehicles' ? 'Vehicles' : 'Devices'}</h3>
                </div>
                <Row className="mt-4 w-100">
                    <Col className="pb-5">
                        <Card className="py-2 card-box border-1">
                            <CardHeader className="w-100 d-flex">
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <ButtonGroup
                                        className="org-button-group"
                                        style={{ width: '23rem', padding: '3px' }}
                                    >
                                        <Button
                                            data-testid="vehicleBtn"
                                            className={` ${
                                                activeTab === 'vehicles'
                                                    ? 'org-button-selected'
                                                    : ''
                                            } org-button`}
                                            style={{ height: '2.5rem', borderRadius: '6px' }}
                                            onClick={() => {
                                                setActiveTab('vehicles')
                                            }}
                                        >
                                            Vehicles
                                        </Button>
                                        <Button
                                            data-testid="deviceBtn"
                                            className={`${
                                                activeTab === 'devices' ? 'org-button-selected' : ''
                                            } org-button`}
                                            style={{ height: '2.5rem', borderRadius: '6px' }}
                                            onClick={() => {
                                                setActiveTab('devices')
                                            }}
                                        >
                                            Devices
                                        </Button>
                                    </ButtonGroup>
                                    <ColumnEditor
                                        toggle={toggleEdit}
                                        dropdownOpen={dropdownOpen}
                                        activeTab={activeTab}
                                        list={
                                            activeTab === 'vehicles' ? columnsList : devicesColumns
                                        }
                                        setList={
                                            activeTab === 'vehicles'
                                                ? setColumnsList
                                                : setDevicesColumns
                                        }
                                    />
                                </div>
                            </CardHeader>
                            <div className="rounded-table-container">
                                {activeTab === 'vehicles' ? (
                                    vehicles.teamVehicle.length > 0 ? (
                                        <BootstrapTable
                                            bootstrap4={true}
                                            classes="my-custom-table"
                                            keyField="uuid"
                                            data={dataList}
                                            columns={visibleColumns || []}
                                            pagination={paginationFactory({
                                                page: lastClickedPageVeh,
                                                sizePerPage: 7,
                                                sizePerPageList: [],
                                                onPageChange: (page) => setLastClickedPageVeh(page),
                                            })}
                                        />
                                    ) : (
                                        <h5 className="p-4">There are currently no vehicles.</h5>
                                    )
                                ) : null}

                                {activeTab === 'vehicles' &&
                                    vehicles.team_vehicles_next &&
                                    lastClickedPageVeh ===
                                        Math.ceil(vehicles.teamVehicle.length / 7) && (
                                        <Button
                                            style={{
                                                position: 'absolute',
                                                bottom: '0.8rem',
                                                left: '24px',
                                                borderRadius: '8px',
                                                background: '#f9fafb',
                                                color: '#4e5462',
                                            }}
                                            onClick={() =>
                                                setCurrentPageNumber(currentPageNumber + 1)
                                            }
                                        >
                                            Load more (
                                            {vehicles.team_vehicles_count -
                                                vehicles.teamVehicle.length}
                                            )
                                        </Button>
                                    )}

                                {activeTab === 'devices' ? (
                                    devices.length > 0 ? (
                                        <BootstrapTable
                                            bootstrap4={true}
                                            classes="my-custom-table"
                                            keyField="uuid"
                                            data={devicesList}
                                            columns={visibleDeviceColumns}
                                            pagination={paginationFactory({
                                                page: lastClickedPageDev,
                                                sizePerPage: 7,
                                                sizePerPageList: [],
                                                onPageChange: (page) => setLastClickedPageDev(page),
                                            })}
                                        />
                                    ) : (
                                        <h5 className="p-4">There are currently no devices.</h5>
                                    )
                                ) : null}
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
}

export default VehicleManage
