import React from 'react'
import {
    faBatteryEmpty,
    faBatteryFull,
    faBatteryHalf,
    faBatteryQuarter,
    faBatteryThreeQuarters,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Battery = ({ percentage, uuid }) => {
    if (!percentage) return <span className='d-flex justify-content-center'>-</span>

    const BatteryIndicator = () => {
        if (percentage <= 25)
            return (
                <FontAwesomeIcon
                    id={`bt_${uuid}`}
                    icon={faBatteryQuarter}
                    className="text-danger mt-1"
                />
            )
        else if (percentage <= 50)
            return (
                <FontAwesomeIcon
                    id={`bt_${uuid}`}
                    icon={faBatteryHalf}
                    className="text-warning mt-1"
                />
            )
        else if (percentage <= 79)
            return (
                <FontAwesomeIcon
                    id={`bt_${uuid}`}
                    icon={faBatteryThreeQuarters}
                    className="text-success mt-1"
                />
            )
        else if (percentage <= 100)
            return (
                <FontAwesomeIcon
                    id={`bt_${uuid}`}
                    icon={faBatteryFull}
                    className="text-success mt-1"
                />
            )

        return (
            <FontAwesomeIcon id={`bt_${uuid}`} icon={faBatteryEmpty} className="text-danger mt-1" />
        )
    }
    return (
        <div className="d-flex justify-centent-center align-item-center ml-2">
            <span className="mr-2">{Math.round(percentage)}%</span>
            <BatteryIndicator />
        </div>
    )
}

export default Battery
