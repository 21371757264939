import React from 'react'
import cx from 'classnames'
import { UncontrolledTooltip } from 'reactstrap'
import { formatDate } from './organization/OrgTeamHelpers'
import { Loading } from 'ui/common'

const NotificationBody = ({ items, status, handleDelete }) => {
    if (status === 'team-notifications-error')
        return <p className="text-center text-danger">Something went wrong</p>
    if (status === 'loading') return <Loading />
    if (!items) return <p className="mt-3 text-center text-info">Notification center is empty!</p>

    return items.map((item, key) => (
        <div
            className={cx('mb-1 rounded-0', {
                'read ': !item.read,
            })}
            key={item._id}
            style={{ paddingBottom: '10px' }}
        >
            <div className="d-flex flex-column align-items-start justify-content-start">
                {item.notification_type === 'system' && (
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div>
                            <img
                                alt="system"
                                width={48}
                                height={48}
                                id={`system-${key}`}
                                src="/svgs/dashboard-icons/settings-icon.svg"
                            />
                            <span
                                className="border-none rounded px-1 bg-grey"
                                style={{ color: '#656D7E' }}
                            >
                                {formatDate(item._created)}
                            </span>
                            <UncontrolledTooltip placement="top" target={`system-${key}`}>
                                System notification
                            </UncontrolledTooltip>
                        </div>
                        <img
                            src="/svgs/close-icon/x-dark-default.svg"
                            alt="obstacle-icon"
                            id={`system-${key}`}
                            width="18px"
                            height="18px"
                            style={{ cursor: 'pointer', placeSelf: 'flex-start' }}
                            onClick={() => handleDelete(item._id)}
                        />
                    </div>
                )}
                {item.notification_type === 'vehicle' && (
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div>
                            <img
                                alt="vehicle"
                                width={48}
                                height={48}
                                id={`system-${key}`}
                                src="/svgs/dashboard-icons/vehicle-icon.svg"
                            />
                            <span
                                className="border-none rounded px-1 bg-grey"
                                style={{ color: '#656D7E' }}
                            >
                                {formatDate(item._created)}
                            </span>
                            <UncontrolledTooltip placement="top" target={`vehicle-${key}`}>
                                Vehicle notification
                            </UncontrolledTooltip>
                        </div>
                        <img
                            src="/svgs/close-icon/x-dark-default.svg"
                            alt="obstacle-icon"
                            id={`system-${key}`}
                            width="18px"
                            height="18px"
                            style={{ cursor: 'pointer', placeSelf: 'flex-start' }}
                            onClick={() => handleDelete(item._id)}
                        />
                    </div>
                )}
                <p
                    className={cx('unread ml-3 ', {
                        read: item.read,
                    })}
                >
                    {item.text}
                </p>
            </div>
        </div>
    ))
}

export default NotificationBody
