import React, { useEffect, useMemo, useState } from 'react'
import { selectors } from 'features/dashboard'
import { useSelector, useDispatch } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import {
    Row,
    Button,
    Col,
    ModalBody,
    Modal,
    Card,
    CardHeader,
    ButtonGroup,
    UncontrolledTooltip,
} from 'reactstrap'
import {
    cleanFormValues,
    getActionsPresets,
    getPresetTasksV2,
    getScheduleTasksV2,
    getTaskDefinitions,
} from 'features/dashboard/Task.actionTypes'
import paginationFactory from 'react-bootstrap-table2-paginator'
import PreSetTaskFormNew from '../Tasks/PresetTaskFormNew'
import {
    actionsPresetsColumns,
    schedulesList,
    definitionList,
    presetsColumns,
    triggersColumns,
} from './ActionsManageColumns'
import RemoveModal from '../MapComponents/RemoveModal'
import DefinitionCreation from './DefinitionCreation'
import { formatDateToDdMmYy } from 'utils'
import { getDevices } from 'features/dashboard/RosSetup.actionTypes'
import { getTriggers } from 'features/dashboard/Trigger.actionTypes'
import TriggerModal from './TriggerModal'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import ScheduledMissionEditModal from './ScheduledMissionEditModal'
import Filter from 'layout-components/Filter'
import { getMapStations } from 'features/dashboard/Map.actionTypes'
import { getTeamVehicles, getVehicleCategories } from 'features/dashboard/Vehicle.actionTypes'
import { TITLE } from 'config'

const TriggerManage = ({ match }) => {
    const { slug } = match.params
    const dispatch = useDispatch()
    const map = useSelector(selectors.getTeamMap)
    const taskStore = useSelector(selectors.getTask)
    const devices = useSelector(selectors.getDevicess)
    const { triggers } = useSelector(selectors.getTriggers)
    const [activeTab, setActiveTab] = useState('presets')
    const [modal, setModal] = useState(false)
    const [removeModal, setRemoveModal] = useState(false)
    const [dataOnClick, setDataOnClick] = useState({})
    const [btnText, setButtonText] = useState('Mission preset')
    const [noDataText, setNoDataText] = useState('mission presets')
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [lastClickedPage, setLastClickedPage] = useState(1)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [initialFilterValues, setInitialFilterValues] = useState({
        stations: [],
    })

    useEffect(() => {
        document.title = `${TITLE} - Actions`
    }, [])

    const toggle = () => {
        setModal(!modal)
        dispatch(cleanFormValues())
    }

    const close = () => {
        setModal(false)
        setDataOnClick({})
    }

    const dropdownToggle = () => setDropdownOpen(!dropdownOpen)
    const toggleRemove = () => setRemoveModal(!removeModal)

    useEffect(() => {
        if (!modal) {
            dispatch(cleanFormValues())
            setDataOnClick({})
        }
    }, [modal]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (currentPageNumber > 1) dispatch(getPresetTasksV2({ slug, page: currentPageNumber }))
    }, [currentPageNumber]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(getDevices({ slug }))
        dispatch(getVehicleCategories({ slug }))
        dispatch(getTeamVehicles({ slug, text: 'status=true' }))
        dispatch(getMapStations({ uuid: map.areas.uuid }))
        dispatch(getTriggers({ slug }))
        dispatch(getActionsPresets({ slug }))
        dispatch(getTaskDefinitions(slug))
        dispatch(getScheduleTasksV2({ slug }))
        dispatch(getPresetTasksV2({ slug, page: currentPageNumber }))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        switch (activeTab) {
            case 'presets':
                setButtonText('Mission preset')
                setNoDataText('mission presets')
                return
            case 'definitions':
                setButtonText('Action definition')
                setNoDataText('action definitions')
                return
            case 'actions-presets':
                setButtonText('Action preset')
                setNoDataText('action presets')
                return
            case 'triggers':
                setButtonText('Trigger')
                setNoDataText('triggers')
                return
            default:
                setButtonText('Mission schedule')
                setNoDataText('scheduled missions')
        }
    }, [activeTab])

    const showActionsLength = () => {
        switch (activeTab) {
            case 'presets':
                if (taskStore.presetsV2.length) return 0
                return taskStore?.presetsV2?.count || 0
            case 'definitions':
                return taskStore?.taskDefinitions.length || 0
            case 'actions-presets':
                return taskStore?.actionsPresets?.length || 0
            case 'triggers':
                return triggers?.length || 0
            default:
                return taskStore.schedulesV2.length || 0
        }
    }

    const modalSwitcher = () => {
        switch (activeTab) {
            case 'presets':
            case 'actions-presets':
                return (
                    <PreSetTaskFormNew
                        toggle={toggle}
                        taskStore={taskStore}
                        stations={map?.stations}
                        devices={devices}
                        actions={taskStore?.taskDefinitions}
                        actionsPresets={taskStore?.actionsPresets}
                        activeTab={activeTab}
                        preset={dataOnClick}
                        currentPageNumber={currentPageNumber}
                        toggleRemove={toggleRemove}
                    />
                )
            case 'definitions':
                return (
                    <DefinitionCreation
                        slug={slug}
                        toggle={toggle}
                        taskStore={taskStore}
                        toggleRemove={toggleRemove}
                        definitions={taskStore?.taskDefinitions}
                        definition={dataOnClick}
                    />
                )
            case 'triggers':
                return (
                    <TriggerModal
                        slug={slug}
                        toggle={toggle}
                        areaUuid={map.areas.uuid}
                        triggers={triggers}
                        trigger={dataOnClick}
                        tasks={taskStore?.presetsV2.results}
                        toggleRemove={toggleRemove}
                    />
                )
            case 'missions':
                return (
                    <ScheduledMissionEditModal
                        slug={slug}
                        toggle={toggle}
                        taskStore={taskStore}
                        mission={dataOnClick}
                        missions={taskStore?.schedulesV2}
                        toggleRemove={toggleRemove}
                    />
                )
            default:
                return
        }
    }

    const dataSwitcher = () => {
        switch (activeTab) {
            case 'presets':
                return dataPresets
            case 'definitions':
                return dataDefinitions
            case 'actions-presets':
                return dataActionPresets
            case 'triggers':
                return dataTriggers
            default:
                return dataSchedules
        }
    }

    const columnsSwitcher = () => {
        switch (activeTab) {
            case 'presets':
                return presetsColumns
            case 'definitions':
                return definitionList
            case 'actions-presets':
                return actionsPresetsColumns
            case 'triggers':
                return triggersColumns
            default:
                return schedulesList
        }
    }

    const RenderTriggereManage = ({ action }) => (
        <div className="d-flex justify-content-center align-items-center">
            <img
                src="/svgs/map-editor/trash.svg"
                alt="trash"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    setDataOnClick(action)
                    toggleRemove()
                }}
            />
            <img
                style={{
                    cursor: 'pointer',
                    marginLeft: '1rem',
                }}
                src="/svgs/trigger-actions/edit.svg"
                alt="edit"
                onClick={() => {
                    setDataOnClick(action)
                    toggle()
                }}
            />
        </div>
    )

    const RenderDefinitionsManage = ({ action }) => (
        <div className="d-flex justify-content-center align-items-center">
            <img
                style={{
                    cursor: action.system_generated ? 'not-allowed ' : 'pointer',
                    pointerEvents: action.system_generated ? ' none ' : '',
                    opacity: action.system_generated ? ' 0.5 ' : '',
                }}
                src="/svgs/map-editor/trash.svg"
                alt="trash"
                onClick={() => {
                    setDataOnClick(action)
                    toggleRemove()
                }}
            />
            <img
                style={{
                    cursor: action.system_generated ? 'not-allowed ' : 'pointer',
                    pointerEvents: action.system_generated ? 'none ' : '',
                    opacity: action.system_generated ? ' 0.5 ' : '',
                    marginLeft: '1rem',
                }}
                src="/svgs/trigger-actions/edit.svg"
                alt="edit"
                onClick={() => {
                    setDataOnClick(action)
                    toggle()
                }}
            />
        </div>
    )

    const dataPresets = useMemo(() => {
        if (!taskStore.presetsV2.results) return []
        return taskStore?.presetsV2?.results.map((preset) => {
            const date = new Date(preset?.metadata.created_at)
            return {
                uuid: preset?.uuid,
                title: preset?.title,
                created_at: formatDateToDdMmYy(date),
                details: <span>{preset.details}</span>,
                update: <RenderTriggereManage action={preset} key={preset.uuid} />,
            }
        })
    }, [taskStore.presetsV2.results])

    const dataDefinitions = useMemo(() => {
        if (!taskStore.taskDefinitions) return []
        return taskStore.taskDefinitions.map((definition) => ({
            uuid: definition?.uuid,
            title: definition?.displayable_name,
            type: definition.system_generated ? 'System default' : 'Custom created',
            description: <span>{definition.description}</span>,
            wait_confirmation: definition.wait_for_confirmation,
            user_input_needed: definition.user_input_needed,
            variables: definition.form
                ? definition.form.variables?.map((variable, key) => (
                      <span key={key}>
                          {variable.variable_name} ({variable.variable_type}) <br />
                      </span>
                  ))
                : 'No form values',
            update: <RenderDefinitionsManage action={definition} key={definition.uuid} />,
        }))
    }, [taskStore.taskDefinitions])

    const dataActionPresets = useMemo(() => {
        if (!taskStore.actionsPresets) return []
        return taskStore?.actionsPresets.map((preset) => ({
            uuid: preset?.uuid,
            title: preset?.displayable_name,
            type: preset?.definition_information.displayable_name,
            details: (
                <span>
                    {preset.definition_information?.displayable_name === 'Move to point'
                        ? `Move to ${preset.point_information?.title || 'point'}`
                        : `${preset.definition_information?.displayable_name} at ${
                              preset.point_information?.title || 'point'
                          }`}
                </span>
            ),
            update: <RenderTriggereManage action={preset} key={preset.uuid} />,
        }))
    }, [taskStore.actionsPresets])

    const dataSchedules = useMemo(() => {
        if (!taskStore.schedulesV2) return []
        return taskStore.schedulesV2.map((schedule) => {
            const subtaskArray = schedule.task_preset.subtasks
            const subtaskText = subtaskArray.map((subtask, index) => {
                if (index === subtaskArray.length - 1) {
                    return subtask.action.definition_information.displayable_name
                } else {
                    return `${subtask.action.definition_information.displayable_name} - `
                }
            })

            const result = <span>{subtaskText.join('')}</span>
            const date = new Date(schedule?.date_tz)
            return {
                uuid: schedule?.uuid,
                title: schedule?.name,
                execute_at: `${formatDateToDdMmYy(date)}
                ${schedule?.time_tz}h`,
                details: <span>{result}</span>,
                enabled: schedule.enabled,
                offline: schedule.offline,
                update: <RenderTriggereManage action={schedule} key={schedule.uuid} />,
            }
        })
    }, [taskStore.schedulesV2])

    const dataTriggers = useMemo(() => {
        if (!triggers) return []
        return triggers.map((trigger) => ({
            uuid: trigger?.uuid,
            title: trigger?.verbose_name,
            action: trigger?.action,
            ip_whitelist: trigger?.ip_whitelist.join(', '),
            initialized: trigger?.initialized,
            update: <RenderTriggereManage action={trigger} key={trigger.uuid} />,
        }))
    }, [triggers])

    return (
        <div>
            <div className="w-100" style={{ marginTop: '-45px' }}>
                <h3>Actions</h3>
            </div>
            <Row className="mt-4 w-100">
                <Col className="pb-5">
                    <Card className="mb-3 card-box border-1">
                        <CardHeader className="w-100 d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <ButtonGroup
                                    className="org-button-group"
                                    style={{ width: '45rem', padding: '3px' }}
                                >
                                    <Button
                                        className={` ${
                                            activeTab === 'presets' ? 'org-button-selected' : ''
                                        } org-button`}
                                        style={{ height: '2.5rem', borderRadius: '6px' }}
                                        onClick={() => {
                                            setActiveTab('presets')
                                        }}
                                    >
                                        Mission presets
                                    </Button>
                                    <Button
                                        className={`${
                                            activeTab === 'missions' ? 'org-button-selected' : ''
                                        } org-button`}
                                        style={{ height: '2.5rem', borderRadius: '6px' }}
                                        onClick={() => {
                                            setActiveTab('missions')
                                        }}
                                    >
                                        Mission schedules
                                    </Button>
                                    <Button
                                        className={`${
                                            activeTab === 'definitions' ? 'org-button-selected' : ''
                                        } org-button`}
                                        style={{ height: '2.5rem', borderRadius: '6px' }}
                                        onClick={() => {
                                            setActiveTab('definitions')
                                        }}
                                    >
                                        Action definitions
                                    </Button>
                                    <Button
                                        className={`${
                                            activeTab === 'actions-presets'
                                                ? 'org-button-selected'
                                                : ''
                                        } org-button`}
                                        style={{ height: '2.5rem', borderRadius: '6px' }}
                                        onClick={() => {
                                            setActiveTab('actions-presets')
                                        }}
                                    >
                                        Action presets
                                    </Button>
                                    <Button
                                        className={`${
                                            activeTab === 'triggers' ? 'org-button-selected' : ''
                                        } org-button`}
                                        style={{ height: '2.5rem', borderRadius: '6px' }}
                                        onClick={() => {
                                            setActiveTab('triggers')
                                        }}
                                    >
                                        Triggers
                                    </Button>
                                </ButtonGroup>
                                <span
                                    className="text-meili rounded px-2 ml-4"
                                    style={{ backgroundColor: '#F9EFFE' }}
                                >
                                    {`${showActionsLength()} ${noDataText}`}
                                </span>
                            </div>
                            <div className="d-flex align-items-center">
                                {activeTab === 'missions' ? (
                                    <>
                                        <img
                                            src="/svgs/trigger-actions/question-mark.svg"
                                            alt="plus"
                                            id="mission-question"
                                            style={{ cursor: 'pointer', color: '#C2C5CE' }}
                                        />
                                        <UncontrolledTooltip
                                            placement="left"
                                            target="mission-question"
                                            style={{
                                                maxWidth: '20rem',
                                                background: 'white',
                                                textAlign: 'start',
                                                borderRadius: '1rem',
                                                whiteSpace: 'break-spaces',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            Missions can be scheduled on the mission page
                                        </UncontrolledTooltip>
                                    </>
                                ) : (
                                    <div className="d-flex">
                                        {(activeTab === 'presets' ||
                                            activeTab === 'actions-presets') && (
                                            <Filter
                                                table="actions"
                                                slug={slug}
                                                initValues={initialFilterValues}
                                                setInitValues={setInitialFilterValues}
                                                setLastClickedPage={setLastClickedPage}
                                                dropdownOpen={dropdownOpen}
                                                toggle={dropdownToggle}
                                                activeTab={activeTab}
                                                stations={map?.stations}
                                            />
                                        )}
                                        <Button
                                            className="save-btn-modal modals-new-btns"
                                            style={{
                                                border: 'none',
                                                height: '2.5rem',
                                                marginLeft: '12px',
                                            }}
                                            onClick={toggle}
                                        >
                                            <img
                                                src="/svgs/trigger-actions/add.svg"
                                                alt="plus"
                                                style={{ marginRight: '8px' }}
                                            />
                                            {btnText}
                                        </Button>
                                    </div>
                                )}

                                {/* <img
                                    src="/svgs/trigger-actions/settings-dots.svg"
                                    alt="plus"
                                    style={{ cursor: 'pointer' }}
                                /> */}
                            </div>
                        </CardHeader>
                        {dataSwitcher().length === 0 && taskStore?.status === 'loaded' ? (
                            <h5 className="p-4">There are currently no {noDataText}.</h5>
                        ) : taskStore.status === 'loading' && !modal ? (
                            <div
                                className="rounded bg-white w-100 pb-2"
                                style={{ minHeight: '45rem' }}
                            >
                                <LoadingSpinner />
                            </div>
                        ) : (
                            <div className="rounded bg-white w-100 pb-2">
                                {
                                    <BootstrapTable
                                        bootstrap4={true}
                                        keyField="uuid"
                                        data={dataSwitcher()}
                                        columns={columnsSwitcher()}
                                        pagination={paginationFactory({
                                            page: lastClickedPage,
                                            sizePerPage: 10,
                                            sizePerPageList: [],
                                            onPageChange: (page) => setLastClickedPage(page),
                                        })}
                                    />
                                }
                            </div>
                        )}
                        {activeTab === 'presets' &&
                            taskStore.status === 'loaded' &&
                            taskStore.presetsV2.next &&
                            taskStore.presetsV2.results.length < taskStore.presetsV2.count &&
                            lastClickedPage ===
                                Math.ceil(taskStore.presetsV2.results.length / 10) && (
                                <Button
                                    style={{
                                        position: 'absolute',
                                        bottom: '0.8rem',
                                        left: '24px',
                                        borderRadius: '8px',
                                        background: '#f9fafb',
                                        color: '#4e5462',
                                    }}
                                    onClick={() => setCurrentPageNumber(currentPageNumber + 1)}
                                >
                                    Load more (
                                    {taskStore.presetsV2.count - taskStore.presetsV2.results.length}
                                    )
                                </Button>
                            )}
                    </Card>
                </Col>
            </Row>

            <Modal className="new-modals" centered isOpen={modal} toggle={toggle}>
                <ModalBody>{modalSwitcher()}</ModalBody>
            </Modal>

            <Modal className="new-modals" centered isOpen={removeModal} toggle={toggleRemove}>
                <ModalBody>
                    <RemoveModal
                        slug={slug}
                        data={dataOnClick}
                        type="presets"
                        toggle={toggleRemove}
                        toggleBig={close}
                        setDataOnClick={setDataOnClick}
                    />
                </ModalBody>
            </Modal>
        </div>
    )
}
export default TriggerManage
